<template>
  <div class="w-100">
    <div class="overflow-auto" style="max-height: 400px" ref="point-wrapper">
      <div class="d-flex justify-content-start py-2">
        <h5>이벤트 참여 유저</h5>
      </div>
      <b-table-simple
          v-if="!pending"
      >
        <b-thead>
          <b-tr>
<!--            <b-th>_id</b-th>-->
            <b-th>userId</b-th>
            <b-th>bonusPoint</b-th>
            <b-th>DATE</b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="users.length">
          <b-tr
              v-for="h in users"
              :key="`payment-history-${h._id}`"
          >
<!--            <b-td>
              <div style="font-size:0.6rem;">
                {{ h._id }}
              </div>
            </b-td>-->
            <b-td>
              {{ h.userId }}
            </b-td>
            <b-td>
              {{ h.bonusPoint }}p
            </b-td>
            <b-td>
              {{ new Date(h.regDate).toLocaleString() }}
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tbody v-else>
          <b-tr>
            <b-td colspan="5">
              NO DATA
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div
          v-else
          class="d-flex justify-content-center"
      >
        <b-spinner
            variant="danger"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "eventUseUsers",
  props: {
    selectedId: String
  },
  data: () => ({
    pending: false,
    page: 1,
    users: []
  }),
  created () {
    (async () => {
      await this.load()
    })()
  },
  methods: {
    async load () {
      this.pending = true
      try {
        const { data } = await this.axios({
          url: `/admin/point/event/users/${this.selectedId}`,
          method: 'GET'
        })
        const { result, users } = data
        console.log(users)
        if (result) this.users = users
      } catch (e) {

      }
      this.pending = false
    }
  }
}
</script>

<style scoped>
th, td {
  font-size: 0.8rem;
  vertical-align: middle;
}
</style>
